import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Cards, Card } from '@accodeing/gatsby-theme-heimr'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

import Form from 'components/Form'
import Layout from 'components/Layout'
import PricingCard from 'components/PricingCard'
import Checkmark from 'components/Checkmark'
import siteMetadata from 'query/SiteMetadata'
import ReferenceSection from 'components/ReferenceSection'

const SMALL_TIER = 'paket-liten'
const MEDIUM_TIER = 'paket-medium'
const LARGE_TIER = 'paket-stor'

const Index = ({ data }) => {
  const [selectedTier, selectTier] = useState(undefined)

  const tierSelected = selectedTier !== undefined

  const deselectCard = () => selectTier(undefined)

  const tierContactForm = classNames('card', 'tier-contact', {
    'tier-contact--open': selectedTier,
  })

  const cardSelected = (tier) => selectedTier === tier
  const cardHidden = (tier) => tierSelected && selectedTier !== tier

  const { email, phone } = siteMetadata()

  const HeaderImage = ({ className }) => {
    const classes = classNames(className, 'header__image--prominent')

    return (
      <GatsbyImage
        image={data.header.childImageSharp.gatsbyImageData}
        className={classes}
        imgStyle={{ objectPosition: '0% 100%' }}
        alt='Bild över skogslandskap, med trätoppar i förgrunden'
      />
    )
  }

  return (
    <Layout
      seo={{ title: 'Hem' }}
      HeaderImage={HeaderImage}
      skipReferences={true}
    >
      <oma-grid-row>
        <section className='section'>
          <h1 className='page__heading'>
            Vi hanterar er ekonomi och administration så att ni kan fokusera på
            er kärnverksamhet
          </h1>
        </section>
        <section className='section section--column-layout'>
          <div>
            <p className='section__text'>
              Hinner du inte med din bokföring? Kämpar du ständigt med
              prioriteringar? Har du inte tid att söka den informationen du
              behöver? Hade dina arbetssätt kunnat bli mer organiserade och
              effektivare för att bli mer produktiva?
            </p>
            <p className='section__text'>
              Vi på The Planner hjälper våra kunder att få kontroll på samt
              förstå sin företagsekonomi. Vi är en hjälpande hand i det
              administrativa arbetet och kundernas professionella bollplank där
              vi förespråkar en öppen dialog. Tillsammans med våra kunder sätter
              vi mål och uppnår dem.
            </p>
            <p className='section__text'>
              Vi jobbar digitalt och är lätta att nå. Vi är flexibla och
              kunniga. Vi ger det lilla extra och finns alltid här för er! Ta
              gärna reda på mer genom att klicka in på fliken ”om oss” och tveka
              inte att kontakta oss!
            </p>
          </div>
          <GatsbyImage
            image={data.planning.childImageSharp.gatsbyImageData}
            className='section__column-right'
            alt='Kvinna i vit tröja skriver i en bok på ett grått skrivbord, där det även står en laptop.'
          />
        </section>
        <section className='section'>
          <h2 className='section__heading'>Ekonomikonsult</h2>
          <Cards className='cards'>
            <PricingCard
              deselect={() => deselectCard()}
              selected={cardSelected(SMALL_TIER)}
              hidden={cardHidden(SMALL_TIER)}
              onClick={() => selectTier(SMALL_TIER)}
              price='990'
              title='Liten'
            >
              <ul className='card__content'>
                <li>
                  <i className='far fa-running'></i> Löpande bokföring
                </li>
                <li>
                  <i className='fal fa-file-invoice-dollar'></i> Momsredovisning
                </li>
                <li>
                  <i className='fal fa-file-chart-line'></i> Rapporter
                </li>
              </ul>
            </PricingCard>
            <PricingCard
              deselect={() => deselectCard()}
              selected={cardSelected(MEDIUM_TIER)}
              hidden={cardHidden(MEDIUM_TIER)}
              onClick={() => selectTier(MEDIUM_TIER)}
              price='2490'
              prominent={!tierSelected}
              title='Mellan'
            >
              <ul className='card__content'>
                <li>
                  <i className='fal fa-long-arrow-left'></i> Som liten
                </li>
                <li>
                  <i className='fal fa-file-invoice'></i> Koll på kundfakturor
                </li>
                <li>
                  <i className='fal fa-calendar-alt'></i> Veckobokföring
                </li>
              </ul>
            </PricingCard>
            <PricingCard
              deselect={() => deselectCard()}
              selected={cardSelected(LARGE_TIER)}
              hidden={cardHidden(LARGE_TIER)}
              onClick={() => selectTier(LARGE_TIER)}
              price='4990'
              title='Stor'
            >
              <ul className='card__content'>
                <li>
                  <i className='fal fa-long-arrow-left'></i> Som mellan
                </li>
                <li>
                  <i className='fal fa-thumbs-up'></i> Betalar alla levfakturor
                </li>
                <li>
                  <i className='fal fa-check-double'></i> Bokför 2ggr/vecka
                </li>
              </ul>
            </PricingCard>
            <Card className={tierContactForm}>
              <h3 className='tier-contact__sub-heading'>Vi kontaktar dig</h3>
              {selectedTier && (
                <Form>
                  <input
                    type='hidden'
                    defaultValue={selectedTier}
                    name='paket'
                  />
                </Form>
              )}
              <h3 className='tier-contact__sub-heading'>
                Vill du hellre kontakta oss?
              </h3>
              <p className='section__text'>
                Maila oss på <oma-link to={email} class='contact-form__link' />
                eller ring <oma-link to={phone} class='contact-form__link' />.
              </p>
            </Card>
          </Cards>
        </section>
        <ReferenceSection />
        <section className='section'>
          <h3 className='section__sub-heading'>
            Löpande bokföring
            <Checkmark />
          </h3>
          <p className='section__text'>
            Under den löpande bokföringen bokför vi alla transaktioner och
            händelser varje månad.
          </p>
          <h3 className='section__sub-heading'>
            Momsredovisning <Checkmark />
          </h3>
          <p className='section__text'>
            Oavsett om du har kvartalsmoms eller månadsmoms så ser vi till att
            Skatteverket får era deklarationer i tid.
          </p>
          <h3 className='section__sub-heading'>
            Rapporter
            <Checkmark />
          </h3>
          <p className='section__text'>
            Varje månad får ni en balans- och resultatrapport där ni kan se hur
            ert företags ekonomi ser ut. Vi går igenom om där är något viktigt
            som vi ser har hänt den senaste månaden med ekonomin samt ger
            förslag på förändringar om där bör göras några. Känner man att man
            inte förstår hur man tittar på balans- och resultatrapporterna så
            erbjuder vi kurser där man bland annat kan få hjälp med detta.
          </p>
          <h3 className='section__sub-heading'>
            Kundfakturor
            <Checkmark />
          </h3>
          <p className='section__text'>
            Vi hjälper er att hålla koll på vilka kunder som har betalt eller ej
            betalt sina fakturor. Vill ni att vi ska skicka eventuella
            påminnelser till era kunder så hjälper vi självklart till med det.
          </p>
          <h3 className='section__sub-heading'>
            Leverantörsfakturor
            <Checkmark />
          </h3>
          <p className='section__text'>
            För att ni ska kunna släppa allt med ekonomin så hjälper vi er att
            betala alla era leverantörsfakturor efter ni attesterat dem samt ha
            koll på att de betalas i tid, förutsatt att vi fått tillgång till
            dem.
          </p>
          <h3 className='section__sub-heading'>
            Veckobokföring
            <Checkmark />
          </h3>
          <p className='section__text'>
            För att ni ska kunna ha löpande kontroll på er ekonomi ekonomi så
            hjälper vi även till att veckovis bokföra allt som hänt den senaste
            veckan.
          </p>
          <h3 className='section__sub-heading'>
            Bokföring 2 gånger i veckan
            <Checkmark />
          </h3>
          <p className='section__text'>
            Vi erbjuder även att 2 gånger i veckan bokföra, hantera kund-och
            leverantörsfakturor och annat våra kunder kan tänkas behöva hjälp.
            Detta är en smidig lösning för de företag som har många
            transaktioner och händelser i veckan samt dem som vill ha extra
            koll.
          </p>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default Index

export const query = graphql`
  {
    planning: file(relativePath: { eq: "planning-with-book-and-pencil.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, layout: CONSTRAINED)
      }
    }
    header: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
`
